<template>
  <v-dialog v-model="show" width="500" scrollable persistent class="modal">
    <v-card>
      <v-card-title> Edit Category </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row class="mt-5">
          <v-col cols="12">
            <v-text-field
              ref="focus"
              v-model="form.name"
              label="Category Name"
              :error-messages="form.$getError('name')"
              :hide-details="!form.$getError('name')"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              label="Category Description (optional)"
              v-model="form.description"
              auto-grow
              outlined
              flat
              :hide-details="!form.$getError('description')"
            />
          </v-col>

          <template v-if="showTrainerInput">
            <v-col cols="12">
              <trainer-search-input
                v-model="form.trainer_id"
                :default-value="category.trainer"
                label="Trainer"
                :error-messages="form.$getError('trainer_id')"
                :hide-details="!form.$getError('trainer_id')"
                clearable
                outlined
                flat
              />
            </v-col>
          </template>
          <template v-if="showTrainerInput || showIconInput">
            <v-col cols="12">
              <v-text-field
                ref="focus"
                v-model="form.icon"
                label="Icon (optional)"
                :error-messages="form.$getError('icon')"
                :hide-details="!form.$getError('icon')"
                outlined
                flat
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer />

        <v-btn
          class="mr-4"
          color="error"
          @click="show = false"
          :loading="form.$busy"
          text
        >
          Cancel
        </v-btn>

        <v-btn @click="saveCategory" color="primary" :loading="form.$busy">
          Save Category
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from '@/utils/form'
import { mapActions, mapMutations } from 'vuex'
import { pick } from 'lodash'
import TrainerSearchInput from '@/components/elements/trainers/TrainerSearchInput'

export default {
  name: 'EditCategoryModal',
  components: {
    TrainerSearchInput,
  },
  props: {
    value: {
      type: Boolean,
    },
    category: Object,
    categoryType: String,
    parentCategory: Object,
  },

  data() {
    return {
      show: false,
      form: new Form({
        name: null,
        description: null,
        trainer_id: null,
        icon: null,
      }),
    }
  },

  computed: {
    hasParentCategory() {
      return this.parentCategory && this.parentCategory.id
    },
    showTrainerInput() {
      if (this.hasParentCategory) {
        return this.parentCategory.id === 19 || this.parentCategory.id === 119
      }
      return false
    },
    showIconInput() {
      return this.category.id === 119
    },
  },

  mounted() {
    this.setForm()
  },

  methods: {
    ...mapActions({
      updateSubCategory: 'categories/updateSubCategory',
      updateMainCategory: 'categories/addNewMainCategory',
    }),

    ...mapMutations({
      attachNewSubCategoryToList: 'categories/attachNewSubCategoryToList',
      attachNewMainCategoryToList: 'categories/attachNewMainCategoryToList',
      attachNewSubMainCategoryToList:
        'categories/attachNewSubMainCategoryToList',
    }),

    setForm() {
      this.form = new Form(
        pick(this.category, ['id', 'name', 'description', 'trainer_id', 'icon'])
      )
    },

    saveCategory() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (!this.hasParentCategory) {
        this.saveMainCategory()
      } else {
        this.saveSubCategory()
      }
    },

    async saveMainCategory() {
      this.updateMainCategory(this.form)
        .then((data) => {
          this.show = false
          this.form.$busy = false
          this.form.$reset()
          this.$emit('fetch-categories')
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
          })
        })
    },

    saveSubCategory() {
      this.updateSubCategory({
        parent: this.parentCategory,
        category: this.form,
      })
        .then(({ data }) => {
          this.show = false
          this.form.$busy = false
          this.form.$reset()
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
          })
        })
    },
  },

  watch: {
    value(val) {
      this.show = val
    },

    show(val) {
      this.$emit('input', val)
    },
  },
}
</script>
