<template>
  <v-card height="600px" outlined>
    <v-overlay
      class="primary--text text-center"
      color="grey lighten-4"
      :value="fetchingList"
      :opacity="1"
      absolute
    >
      <v-progress-circular :size="50" color="primary" indeterminate />
      <div class="pa-10">Loading Category List...</div>
    </v-overlay>

    <v-overlay
      class="primary--text text-center"
      color="white"
      :value="
        (isMain && !hasCategories && !fetchingList) ||
          (!fetchingList && !hasCategories && hasParentCategory)
      "
      :opacity="1"
      absolute
    >
      <no-list details="No Category List" width="200" />
      <v-btn @click="addNewCategory" class="px-12" color="primary">
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        <span> Add New Category </span>
      </v-btn>
    </v-overlay>

    <v-card-title v-if="!loading && hasCategories">
      <v-btn
        v-if="!isSorting && searchClosed && !search"
        color="accent"
        @click="isSorting = true"
        text
      >
        Reorder
      </v-btn>

      <v-btn v-if="isSorting" @click="cancelSorting" color="error" text>
        Cancel
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        v-if="isSorting && hasCategorySortChanges"
        v-ripple="{ center: true }"
        color="success"
        @click="saveSortChanges"
        text
      >
        Save Changes
      </v-btn>

      <!-- <v-text-field
        v-model="search"
        class="expanding-search ml-auto v-input--is-focused"
        placeholder="Search Category"
        :class="{ closed: searchClosed && !search }"
        :prepend-inner-icon="icons.search"
        :search-input.sync="search"
        @focus="searchClosed = false"
        @blur="searchClosed = true"
        hide-details
        clearable
        outlined
        dense
      /> -->
    </v-card-title>

    <v-divider v-if="!loading && hasCategories" />

    <div
      v-infinite-scroll="loadMore"
      class="category-list--container draggable-list-container"
      style="position: relative"
      :infinite-scroll-disabled="disableListScroll"
    >
      <draggable
        v-if="!loading"
        tag="div"
        :list="categories"
        :animation="180"
        :force-fallback="true"
        fallback-class="chosen"
        ghost-class="moving-ghost-list"
        :group="`exercise-${type}-categories`"
        handle=".grab-category"
      >
        <template v-for="(category, index) in categories">
          <div :key="index">
            <category-list
              :key="category.id"
              :type="items.type"
              :category="category"
              :parent-category="parent"
              :fetching="isFetchingList"
              :is-editable-list="isEditableList"
              @is-fetching-information="isFetchingInformation"
              @show-category-informartion="showCategoryInformation"
              @deleted-category="removeCategoryFromList"
              @fetch-categories="$emit('fetching-list')"
              :is-sorting="isSorting"
              :draggable="isSorting"
            />
            <v-divider :key="`category-${category.slug}-divider`" />
          </div>
        </template>
      </draggable>
    </div>

    <v-card-actions
      v-if="hasCategories && !loading && !isSorting"
      class="primary lighten-4"
    >
      <v-btn @click="addNewCategory" color="primary" block text>
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        <span> Add New Category </span>
      </v-btn>
    </v-card-actions>

    <add-new-category-modal
      v-model="addNewCategoryModalOpen"
      :parent-category="parent"
      :category-type="type"
    />
  </v-card>
</template>

<script>
import AddNewCategoryModal from '@/components/modals/categories/AddNewCategory'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'
import CategoryList from './CategoryList'
import Draggable from 'vuedraggable'

import { clone, sortBy, isEqual, mapValues } from 'lodash'
import { mdiClose, mdiMagnify, mdiPlus } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'CategoriesCard',

  components: {
    AddNewCategoryModal,
    CategoryList,
    Draggable,
    NoList,
  },

  directives: {
    infiniteScroll,
  },

  props: {
    items: Object,
    title: String,
    loading: Boolean,
    isSortable: Boolean,
    isEditableList: Boolean,
    isFetchingList: Boolean,
    parent: {
      type: Object,
      default: () => ({
        id: null,
        name: null,
        slug: null,
        order: null,
        description: null,
        is_active: null,
        children_count: null,
        children: [],
      }),
    },
  },

  data() {
    return {
      search: null,
      categories: [],
      isSorting: false,
      isSearching: false,
      searchClosed: true,
      fetchingList: false,
      categoryOriginalCopy: [],
      addNewCategoryModalOpen: false,

      icons: {
        add: mdiPlus,
        close: mdiClose,
        search: mdiMagnify,
      },
    }
  },

  created() {
    this.fetchingList = this.loading

    this.categories = this.items.list

    this.categoryOriginalCopy = clone(this.items.list)
  },

  computed: {
    isMain() {
      return this.items.type === 'main'
    },

    hasParentCategory() {
      return !!this.parent.id
    },

    hasCategories() {
      return !!this.items.list.length
    },

    disableListScroll() {
      return this.items.meta.current_page >= this.items.meta.last_page
    },

    type() {
      return this.items.type
    },

    hasCategorySortChanges() {
      return !isEqual(this.categories, this.categoryOriginalCopy)
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'categories/getCategories',
      reorderCategories: 'categories/reorderCategories',
    }),

    ...mapMutations({
      setMainCategories: 'categories/setMainCategories',
      removeCategoryFromList: 'categories/removeCategoryFromList',
    }),

    loadMore() {
      if (this.items.meta.current_page < this.items.meta.last_page) {
        this.$emit('fetching-list', this.items.meta.current_page + 1)
      }
    },

    isFetchingInformation(isFetching) {
      this.$emit('is-fetching-information', isFetching)
    },

    showCategoryInformation(categories) {
      this.$emit('show-category-informartion', categories)
    },

    addNewCategory() {
      this.addNewCategoryModalOpen = true
    },

    cancelSorting() {
      this.isSorting = false
      this.categories = sortBy(this.categories, ['order'])
    },

    async saveSortChanges() {
      let _categories = mapValues(this.categories, 'id')

      await this.reorderCategories(_categories)

      this.isSorting = false
    },
  },

  watch: {
    'items.list'(values) {
      this.categories = values

      if (!this.isSorting) {
        this.categoryOriginalCopy = clone(values)
      }
    },

    loading(value) {
      this.fetchingList = value

      if (value) {
        this.cancelSorting()
      }
    },

    search(value) {
      if (value) {
        // console.log(value, this.type)
      }
    },
  },
}
</script>
