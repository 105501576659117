<template>
  <div class="bbr-categories">
    <main-app-bar :showSearchIcon="false">
      <template v-slot:title> Category Settings </template>
    </main-app-bar>

    <div class="px-12 mb-2 mt-10">
      <h3>Categories</h3>
    </div>

    <div class="px-12 mb-12">
      <v-row>
        <v-col cols="12" sm="4">
          <category-card
            title="Main Categories"
            :items="mainCategories"
            :loading="isFetchingMainCategoriesList"
            :isFetchingList="isFetchingSubMainCategoriesList"
            @fetching-list="fetchMainCategories"
            @is-fetching-information="isFetchingFromMainInformation"
            @show-category-informartion="showMainCategoryInformation"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <category-card
            title="Sub Main Categories"
            :items="SubMainCategories"
            :parent="selectedMainCategory"
            :loading="isFetchingSubMainCategoriesList"
            :isFetchingList="isFetchingSubCategoriesList"
            @is-fetching-information="isFetchingFromSubMainInformation"
            @show-category-informartion="showSubMainCategoryInformation"
            is-editable-list
            is-sortable
          />
        </v-col>

        <v-col cols="12" sm="4">
          <category-card
            title="Sub Categories"
            :items="SubCategories"
            :parent="selectedSubMainCategory"
            :loading="isFetchingSubCategoriesList"
            is-editable-list
            is-sortable
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CategoryCard from '@/components/elements/categories/CategoryCard'
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mdiMagnify, mdiPlus } from '@mdi/js'

export default {
  name: 'CategoriesPage',

  components: {
    MainAppBar,
    CategoryCard,
  },

  data() {
    return {
      isFetchingSubCategoriesList: false,
      isFetchingMainCategoriesList: false,
      isFetchingSubMainCategoriesList: false,

      icons: {
        add: mdiPlus,
        search: mdiMagnify,
      },
    }
  },

  computed: {
    ...mapState({
      selectedMainCategory: (state) => state.categories.selectedMainCategory,
      selectedSubMainCategory: (state) =>
        state.categories.selectedSubMainCategory,
    }),

    ...mapGetters({
      getCategoryState: 'categories/getCategories',
    }),

    mainCategories() {
      return this.getCategoryState('main')
    },

    SubMainCategories() {
      return this.getCategoryState('sub-main')
    },

    SubCategories() {
      return this.getCategoryState('sub')
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'categories/getCategories',
    }),

    ...mapMutations({
      setSubCategories: 'categories/setSubCategories',
      setMainCategories: 'categories/setMainCategories',
      setSubMainCategories: 'categories/setSubMainCategories',
    }),

    async fetchMainCategories(page = 1, search = null) {
      if (this.isFetchingMainCategoriesList) return

      this.isFetchingMainCategoriesList = true

      let { data, meta } = await this.getCategories({ page })

      this.isFetchingMainCategoriesList = false

      this.setMainCategories({ data, meta })
    },

    showMainCategoryInformation(categories) {
      this.setSubMainCategories(categories)
    },

    isFetchingFromMainInformation(isFetching) {
      this.isFetchingSubMainCategoriesList = isFetching
    },

    showSubMainCategoryInformation(categories) {
      this.setSubCategories(categories)
    },

    isFetchingFromSubMainInformation(isFetching) {
      this.isFetchingSubCategoriesList = isFetching
    },
  },
}
</script>

<style lang="scss">
.v-card {
  display: flex !important;
  flex-direction: column;

  .category-list {
    &--container {
      flex-grow: 1;
      overflow: auto;
    }

    &--item {
      &:hover {
        cursor: pointer;
        // background-color: var(--v-primary-lighten4) !important;
      }
    }
  }
}
</style>
