<template>
  <div
    class="category-list--item category-list--active pa-4 align-center d-flex justify-space-between"
    :ripple="true"
    @click="fetchInformation"
    two-line
  >
    <div v-if="draggable" class="mr-2">
      <v-btn @click.stop class="grab-category" icon>
        <v-icon> {{ icons.drag }} </v-icon>
      </v-btn>
    </div>

    <div class="pl-2" :style="{ width: draggable ? '80%' : '90%' }">
      <h1 class="subtitle-1 mb-n1 primary--text">{{ category.name }}</h1>
      <div
        v-if="category.children.length"
        class="caption grey--text long-and-truncated"
      >
        Sub Categories:
        <i> {{ category.childrenNames | readable(', ') }} </i>
      </div>
      <div v-if="category.description" class="caption grey--text">
        {{ category.description }}
      </div>
    </div>

    <div class="ml-auto">
      <v-progress-circular
        v-if="loading"
        class="mr-2"
        color="primary"
        style="margin: 5px;"
        :size="25"
        :width="2"
        indeterminate
      />

      <v-menu v-if="!loading && !isSorting" left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon> {{ icons.actions }} </v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item v-if="isEditableList" link>
            <v-list-item-title class="px-1"> Edit Category</v-list-item-title>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-title class="px-1" @click="editSelectedCategory">
              <span class="mr-3"> Edit Category </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              class="red--text px-1"
              @click="deleteSelectedCategory"
            >
              <span class="mr-3"> Delete Category </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <edit-category-modal
      v-model="showEditCategoryModal"
      :parent-category="parentCategory"
      :category-type="type"
      :category="category"
      @fetch-categories="$emit('fetch-categories')"
    />
  </div>
</template>

<script>
import { mdiDotsHorizontal, mdiTrashCanOutline, mdiDrag } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import EditCategoryModal from '@/components/modals/categories/EditCategory'

export default {
  name: 'CategoryList',

  components: {
    EditCategoryModal,
  },

  props: {
    type: String,
    category: Object,
    parentCategory: Object,
    fetching: Boolean,
    disabled: Boolean,
    draggable: Boolean,
    isSorting: Boolean,
    isEditableList: Boolean,
  },

  data() {
    return {
      loading: false,
      deleting: false,
      showEditCategoryModal: false,

      icons: {
        drag: mdiDrag,
        actions: mdiDotsHorizontal,
        remove: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    isMain() {
      return this.type === 'main'
    },
  },

  methods: {
    ...mapActions({
      deleteCategory: 'categories/deleteCategory',
      getCategorySubCategory: 'categories/getCategorySubCategory',
    }),

    ...mapMutations({
      setSelectedParentCategory: 'categories/setSelectedParentCategory',
      clearCategoryInformation: 'categories/clearCategoryInformation',
    }),

    async fetchInformation() {
      if (this.isSorting) return
      if (this.fetching) return
      if (this.type === 'sub') return

      if (this.type === 'main') {
        this.clearCategoryInformation('main')
      }

      this.setSelectedParentCategory({
        category: this.category,
        type: this.type,
      })

      this.loading = true

      this.$emit('is-fetching-information', this.loading)

      let subCategories = await this.getCategorySubCategory({
        page: 1,
        search: null,
        slug: this.category.slug,
        includes: ['children', 'trainer'],
      })

      await this.$sleep(500)

      this.loading = false

      this.$emit('is-fetching-information', this.loading)
      this.$emit('show-category-informartion', subCategories)
    },

    editSelectedCategory() {
      this.showEditCategoryModal = true
    },

    async deleteSelectedCategory() {
      this.loading = true

      await this.deleteCategory(this.category)

      this.loading = false

      this.$emit('deleted-category', {
        category: this.category,
        type: this.type,
      })
    },
  },
}
</script>

<style>
.long-and-truncated {
  width: 100%;
  line-clamp: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
